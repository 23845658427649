.how-it-works-container .cover-container {
  height: 530px;
  background-color: var(--peel-orange-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 40px;
}

.how-it-works-container .cover-container .cover-text h1 {
  font-family: "Lato";
  color: var(--white-color);
  text-shadow: none;
  line-height: 1.5;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: left;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  font-weight: 900;
}

.how-it-works-container .cover-container .cover-text div {
  font-family: "Lato";
  color: var(--background-color);
  /* font-size: 38px; */
  text-align: center;
}

.how-it-works-container .cover-container .cover-image {
  height: 110%;
}

.how-it-works-container .cover-container .cover-image img {
  width: 100%;
  height: 100%;
}

.how-it-works-container div .steps {
  width: 90%;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 30px;
}

.how-it-works-container .step-card {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  margin: 20px auto;
}

.how-it-works-container .step-card .step-text {
  padding: 1rem 2rem;
  flex: 100%;
}

.how-it-works-container .step-card .step-text h2 {
  font-family: "Lato";
}

.how-it-works-container .step-card .step-text div {
  /* font-size:18px; */
  margin-bottom: 20px;
  font-family: "Lato";
}

.how-it-works-container .step-card .step-text button,
.how-it-works-container .buy-sell-container .buy-sell-cards > div button {
  font-weight: bold;
  /* font-size: 18px; */
  text-transform: uppercase;
}

.how-it-works-container .step-card .step-img {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-it-works-container .step-card img {
  max-width: 100%;
  height: auto;
}

.how-it-works-container .buy-sell-container {
  background-color: #abb8c3;
  padding: 20px 30px;
  width: 90%;
  margin: auto;
  margin-top: 30px;
}

.how-it-works-container .buy-sell-container .buy-sell-intro {
  font-style: italic;
  font-family: "Lato";
  /* font-size: 18px; */
  color: #333333;
  line-height: 30px;
  margin-bottom: 40px;
}

.how-it-works-container .buy-sell-container .buy-sell-cards {
  display: flex;
  justify-content: space-between;
}

.how-it-works-container .buy-sell-container .buy-sell-cards > div {
  background-color: #fff;
  margin: 0px 10px;
  text-align: center;
  padding: 10px;
}

.how-it-works-container .buy-sell-container .buy-sell-cards > div .buy-icon {
  padding: 20px 30px;
  background-color: #f26654;
  width: fit-content;
  margin: auto;
}

.how-it-works-container .buy-sell-container .buy-sell-cards > div .buy-icon i {
  color: #fff;
  /* font-size: 20px; */
}

.how-it-works-container .buy-sell-container .buy-sell-cards > div > div {
  margin-bottom: 20px;
  line-height: 30px;
  /* font-size: 18px; */
}

.how-it-works-container .buy-sell-container .buy-sell-cards > div ul li {
  text-align: left;
  list-style-type: "👉";
  padding-inline-start: 1ch;
}

.how-it-works-container .additional-tip-container {
  width: 90%;
  margin: auto;
  margin-top: 30px;
}

.how-it-works-container
  .additional-tip-container
  .additional-tip-card-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.how-it-works-container
  .additional-tip-container
  .additional-tip-card-container
  .additional-tip-card {
  width: 30%;
  margin: 0px 10px;
  color: var(--dark-grey-color);
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  transition: 0.5s;
}

.how-it-works-container
  .additional-tip-container
  .additional-tip-card-container
  .additional-tip-card:hover {
  cursor: pointer;
  box-shadow: 0px 0px 14px 0px rgba(224, 79, 21, 0.4);
  background-color: #fff;
}

.how-it-works-container
  .additional-tip-container
  .additional-tip-card-container
  .additional-tip-card
  .additional-tip-card-img {
  max-width: 145px;
  margin: auto;
  width: 200px;
  height: 200px;
  margin-bottom: 2rem;
}

.how-it-works-container
  .additional-tip-container
  .additional-tip-card-container
  .additional-tip-card
  .additional-tip-card-img
  img {
  max-width: 100%;
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
}

.how-it-works-container
  .additional-tip-container
  .additional-tip-card-container
  .additional-tip-card
  .additional-item-card-text {
  line-height: 30px;
  /* font-size: 18px; */
}

.how-it-works-container
  .additional-tip-container
  .additional-tip-card-container
  .additional-tip-card
  .additional-tip-card-learn-more {
  margin-top: 10px;
  border-top: 1px solid black;
  background-color: #fff;
  /* font-size: 18px; */
  padding: 1rem;
}

.how-it-works-container .socials {
  width: 90%;
  margin: auto;
  margin: 30px auto;
}

.how-it-works-container .social-btn-container {
  display: flex;
  justify-content: center;
}

.how-it-works-container .social-btn-container div {
  border-radius: 5px;
  padding: 0.7rem 1.4rem;
  min-width: 200px;

  justify-content: center;
}

.buy-sell blockquote {
  font-size: 24px;
  width: 80%;
  margin: 50px auto;
  font-style: italic;
  color: #555555;
  padding: 1.2em 30px 1.2em 75px;
  border-left: 8px solid var(--background-color);
  line-height: 1.6;
  position: relative;
  background: #ededed;
}

.buy-sell blockquote::before {
  content: "\201C";
  color: var(--background-color);
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -10px;
}

.buy-sell blockquote::after {
  content: "";
}

.buy-sell blockquote span {
  display: block;
  color: var(--background-color);
  font-style: normal;
  font-weight: bold;
  margin-top: 1em;
}

.table-resp {
  border-collapse: collapse;
  /* margin-left: 1em; */
  max-width: 80%;
}
.table-resp caption {
  text-align: left;
  width: 95vw;
}

@media (min-width: 768px) {
  .table-resp {
    margin-top: 3em;
    position: relative;
  }
  .table-resp caption {
    position: absolute;
    top: -2em;
    margin-bottom: 0.75em;
  }
  .table-resp thead {
    /* text-align: left; */
    /* background-color: #333; */
    /* color: white; */
  }
  .table-resp td,
  .table-resp th {
    padding: 1.25em 1em;
    color: var(--dark-grey-color);
    border-top: none;
    border-bottom: none;
  }
  .table-resp th {
    color: var(--background-color);
  }
  .table-resp td:nth-child(2),
  .table-resp th:nth-child(2) {
    border-left: 3px solid var(--primary-color);
  }
}
@media (max-width: 767px) {
  thead tr th:last-child {
    font-weight: 100;
  }
  .buy-sell thead {
    display: block;
  }
  .buy-sell thead tr th {
    font-size: 16px !important;
  }

  .table-resp {
    display: block;
    margin-top: 1em;
  }

  .table-resp td {
    display: block;
  }

  .table-resp td:first-child {
    font-weight: 700;
    margin-top: 0.75em;
    margin-bottom: 0;
    border-bottom: 2px solid var(--background-color);
  }

  .table-resp td:nth-child(2) {
    margin-top: 0;
  }
  blockquote {
    font-size: 16px;
  }
}

@media screen and (max-width: 750px) {
  .how-it-works-container {
    /* margin-top: 70px; */
  }
  .how-it-works-container .cover-container {
    flex-direction: column-reverse;
    height: auto;
  }
  .how-it-works-container .step-card {
    flex-direction: column-reverse;
    align-items: center;
    margin: 40px auto;
  }
  .how-it-works-container .step-card .step-img {
    width: 100%;
  }
  .how-it-works-container .buy-sell-container .buy-sell-cards {
    flex-direction: column;
  }
  .how-it-works-container .buy-sell-container .buy-sell-cards > div {
    margin: 10px 0px;
  }
  .how-it-works-container
    .additional-tip-container
    .additional-tip-card-container {
    flex-direction: column;
  }
  .how-it-works-container
    .additional-tip-container
    .additional-tip-card-container
    .additional-tip-card {
    width: 100%;
    margin: 10px 0px;
  }
  .step-card-second {
    flex-direction: column !important;
  }
  .how-it-works-container .step-card .step-text {
    padding: 1rem 0;
  }
  .how-it-works-container .social-btn-container div {
    min-width: min-content;
  }
  .how-it-works-container .social-btn-container {
    flex-direction: column;
  }
}

.testi-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testi-slider {
  max-width: 80%;
  margin: auto;
  text-align: center;
  margin: 2rem 0;
  min-height: 460px;
}

.testimonial-slider .splide__list {
  display: flex;
  align-items: stretch;
}

.testi-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensures equal height */
  background: white;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin: auto;
  text-align: center;
}

.testi-quote {
  display: flex;
  align-items: flex-start;
}

.testi-quote-icon {
  font-size: 42px;
  color: var(--primary-color);
  margin-right: 10px;
}

.testi-text {
  font-style: italic;
  color: var(--grey-color);
}

.testi-author {
  margin-top: 1rem;
  border-bottom: 4px solid var(--background-color);
  border-radius: 4px;
}

.testi-name {
  font-size: 16px;
  font-weight: bold;
  color: var(--dark-grey-color);
}

/* Change pagination dots to flat bars */
.testi-slider .splide__pagination__page {
  width: 16px; /* Increase width to make it look like a bar */
  height: 3px; /* Reduce height */
  border-radius: 3px; /* Slightly rounded edges */
  background: var(--grey-color) !important; /* Default color */
  opacity: 0.5; /* Adjust transparency */
  transition: all 0.3s ease-in-out;
}

/* Active pagination bar */
.testi-slider .splide__pagination__page.is-active {
  width: 24px; /* Make active bar longer */
  background: var(--background-color) !important;
  opacity: 1;
}